import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import {get} from "lodash";
import LaravelDecrypt from "~/packages/core/actions/cryptography/LaravelDecrypt";

/**
 * this class is a single action class that returns true if user has the provided permission into the static method execute
 * otherwise, it returns false
 */
export default class Base64ToBlob extends BaseStaticAction
{
    static override execute(base64: any) {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
}