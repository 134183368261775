import BaseStaticAction from "~/packages/core/actions/BaseStaticAction";
import {get} from "lodash";
import LaravelDecrypt from "~/packages/core/actions/cryptography/LaravelDecrypt";

/**
 * this class is a single action class that returns true if user has the provided permission into the static method execute
 * otherwise, it returns false
 */
export default class BlobToBase64 extends BaseStaticAction
{
    static override async execute(blob: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
        });
    }
}